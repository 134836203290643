@keyframes slidein {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(200%);
  }
}

.skeleton-gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(210, 210, 210, 1) 50%, rgba(0, 212, 255, 0) 100%);
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  animation: 2s linear 1s infinite running slidein;
}
