.h1 {
  font-size: 63px;
}
.h2 {
  font-size: 52px;
}
.h3 {
  font-size: 43px;
}
.h4 {
  font-size: 34px;
}
.h5 {
  font-size: 25px;
}
.h6 {
  font-size: 16px;
}

/* TODO: move to global styles */
.color-primary {
  color: var(--color-primary) !important;
}
.color-secondary {
  color: var(--color-secondary) !important;
}
.color-text-gray {
  color: var(--color-text-gray) !important;
}
.color-text-light {
  color: var(--color-text-light) !important;
}
.color-text {
  color: var(--color-text) !important;
}
.color-alert {
  color: var(--color-alert) !important;
}
.color-warning {
  color: var(--color-warning) !important;
}
