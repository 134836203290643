.button-with-loading .content {
  display: flex;
  align-items: center;
}
.button-with-loading .circle-clipper .circle {
  border-width: 2px;
}
.button-with-loading .preloader-wrapper.active {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.icon-btn.btn-flat {
  padding: 0;
  line-height: inherit;
}
.icon-btn.icon-btn-type-round {
  border-radius: 50%;
}
